var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"SdS06gSwrTWdgJAbSR8Cc"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_DSN } from './src/redux/constants';

Sentry.init({
  dsn: SENTRY_DSN,

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  tracesSampleRate: 0.05,
  allowUrls: [
    /https?:\/\/\w+\.zakaz\.\w+/,
  ],
  ignoreErrors: [
    'Cannot redefine property: googletag', // caused by ad blocker
    'Failed to fetch',
    'Load failed',
    'globalThis is not defined',
    'отменено', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127785/
    'cancelled', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127761/
    'NetworkError when attempting to fetch resource', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127802/
    'скасовано', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127769/
    'AbortError: The operation was aborted', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127751/
    'Превышен лимит времени на запрос', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127763/
    'The request timed out', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/130496/
    'Network request failed', // https://sentry.zakaz.global/organizations/zakaz/issues/138322/?project=54
    'Cannot find function isEqualNode in object', // https://caniuse.com/mdn-api_node_isequalnode
    'Abgebrochen', // https://sentry.zakaz.global/organizations/zakaz/issues/128507/?project=54
    'illegal character U+009E', // https://sentry.zakaz.global/organizations/zakaz/issues/128957/?project=54
    'Non-Error promise rejection captured with value', // https://sentry.zakaz.global/organizations/zakaz/issues/138640/?project=62
    'Сетевое соединение потеряно', // https://sentry.zakaz.global/organizations/zakaz/issues/138702/?project=62
    'Зʼєднання з мережею втрачено', // https://sentry.zakaz.global/organizations/zakaz/issues/142753/?project=62
    'Access is denied for this document', // https://sentry.zakaz.global/organizations/zakaz/issues/138697/?project=62
    'gtag', // https://sentry.zakaz.global/organizations/zakaz/issues/140907/?project=62
    'The operation couldn’t be completed. Invalid argument', // https://sentry.zakaz.global/organizations/zakaz/issues/139060/?project=62
    'The quota has been exceeded', //https://sentry.zakaz.global/organizations/zakaz/issues/180462/?project=62
    'QuotaExceededError', //https://sentry.zakaz.global/organizations/zakaz/issues/183233/?project=62
    'Route did not complete loading', //https://sentry.zakaz.global/organizations/zakaz/issues/180475/?project=62
    'Object doesn\'t support property or method \'finally\'', //https://sentry.zakaz.global/organizations/zakaz/issues/180440/?project=62
    'finally', //https://sentry.zakaz.global/organizations/zakaz/issues/180435/?project=62
    'Failed to execute \'removeChild\' on \'Node\'', //https://sentry.zakaz.global/organizations/zakaz/issues/180448/?project=62
  ],
});
